.documentation-wrapper {
  color: var(--main-font-color);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.documentation-articles {
  width: 85%;
  max-width: 1300px;
  text-align: left;
  margin-top: 50px;
}

/* Article */
.single-article {
  border-bottom: 1px solid var(--main-font-color);
  margin-bottom: 40px;
}

/* Article titles */
.single-article > h2 {
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.single-article > h2:hover::before {
  content: "#";
  opacity: 0.7;
  position: absolute;
  left: -20px;
}

/* Articles */
.single-article > p {
  line-height: 1.5;
}

.dot {
  width: 20px;
  height: 20px;
  background-color: var(--main-font-color); /* Gray color */
  color: var(--main-body-color);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

/* Images */
img {
  width: 80%;
  max-height: 350px;
  max-width: fit-content;
}

/* Images wrapper */
.article-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-block: 10px;
}

/* Warning box */
.warn::before {
  content: "Atenție!";
  font-size: 1.2em;
  font-weight: bold;
  display: block;
  margin-bottom: 10px; /* Add some space between the title and the content */
}

.warn {
  color: var(--button-font-color);
  background: var(--error-background);
  padding: 10px;
  border-radius: .35rem;
}

/* Lists */
li {
    line-height: 1.5;
}
