/*
///---------------------------------------------------------------------------
/! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
/? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------
*/

.App {
  text-align: center;
}

.contrast-text {
  color: var(--contrast-font-color);
}

.text-faded {
  color: var(--faded-font-color);
}

.heavy-text {
  font-weight: 700;
}

.hidden {
  overflow: hidden;
  height: 0;
  /* margin: 5em; */
  position: absolute;
  right: -100em;
}

.danger {
  background-color: #FF3D00 !important;
}

.main-title {
  transition: all .2s ease-in-out;
}

.main-title.hide {
  font-size: 0;
  transition: all .2s ease-in-out;
}

/* WELCOME SPLASH */
.splash-wrapper {
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  padding:0;
  margin:0;
}

.splash-bg {
  z-index: 10;
  top: 0;
  left: 0;
  background-color: #283046;
  width: 100%;
  height: 100%;
  position: fixed;
  padding:0;
  margin:0;

  /* font */
  color: #D0D2D6;
  font-weight: 600;
  font-size: 5vw;

  /* animation */
  animation-name: splashBgUp;
  animation-duration: 3000ms;
  animation-timing-function: ease-in-out;
  animation-delay: 1350ms;
}

.splash-logo {
  width: 100%;
  height: 100%;
  gap: .3em !important;
}

.splash-logo>img {
  width: 10vw;
  user-select: none;
  -webkit-user-drag: none;

  animation-name: splashImage;
  animation-duration: 900ms;
  animation-timing-function: ease-in-out;
  animation-delay: 400ms;
}

@keyframes splashImage {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(10deg) scale(1.3);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

@keyframes splashBgUp {
  0% {
    top: 0;
  }
  50% {
    top: -100em;
  }
  100% {
    top: -100em;
  }
}

/* HEADER */
.logo-image, .splash-logo {
  user-select: none;
  -webkit-user-drag: none;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  gap: 1em;
}

.logo-image {
  position: relative;
  cursor: pointer;
}

.logo-image>img {
  width: 2.5em;
  transition: all 1s ease-in-out;
}

.logo-image:hover >img {
  transition: all 1s ease-in-out;
  transform: translateX(14em) rotate(360deg);
}

.logo-image>#type {
  color: var(--contrast-font-color);
  position: absolute;
  top: 30px;
  right: 0;
  font-size: small;
}


.header-wrapper {
  position: fixed;
  width: 100%;
  z-index: 3;
}

.menu-button {
  position: fixed;
  left: .6em;
  top: .6em;
  display: none;
  z-index: -1;
  cursor: pointer;
  color: var(--main-font-color);
}

.header {
  font-weight: 600;
  color: var(--main-font-color);
  padding-inline: 5em;
  padding-block: 2em;
  display: flex;
  justify-content: space-between;
  background-color: var(--contrast-body-color);
  transition: all .1s ease-in-out;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.top-user-name {
  text-align: right;
}

.top-user-email {
  color: var(--faded-font-color);
  font-size: .8rem;
  text-align: right;
}

.user-top-info {
  cursor: pointer;
}

.right-side {
  padding-inline: 3em;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 2.5em;
  align-items: center;
}

.link.account {
  color: var(--button-color-main);
}

.link {
  cursor: pointer;
  border-left: 5px solid transparent;
  transition: all .1s ease-in-out;
}

.link:hover {
  border-left: 5px solid var(--contrast-font-color);
  transition: all .1s ease-in-out;
}

.after-header {
  padding-bottom: 10em;
}

/* FOOTER */
.footer-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 250px;
  color: var(--main-font-color);
  background-color: var(--contrast-body-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer {
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-inline: 5em;
  padding-block: 2em;
  position: relative;
  bottom: 0;
}

.list-block {
  border-right: 2.5px solid var(--main-font-color);
  padding-inline: 2em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: .7em;
  list-style-type: none;
}

.list-block:last-child {
  border-right: 5px transparent
}

.copyright {
  font-family:'Cairo'
}

/* Welcome page */
.welcome-wrapper {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3em;
  padding-bottom: 10em;
}

.welcome-section {
  background-color: var(--contrast-body-color);
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: var(--main-font-color);
  width: 80%;
  max-width: 1100px;
}

.bubbles {
  color: var(--main-font-color);
  width: 80%;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  align-items: center;
  justify-content: center;
}

.bubble {
  width: 23%;
  height: 250px;
  background-color: var(--contrast-body-color);
  border-radius: 20px;
  box-sizing: border-box;
  padding: 5px;
  margin-bottom: 2%;
}

.bubble > h1 {
  font-size: 1.3rem;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bubble-icon {
  height: 50px;
  font-size: 3rem;
  color: var(--button-color-main);
}

.welcome-section.two-pane {
  background-color: var(--main-body-color);
  border-radius: 0px;
  box-shadow: none;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1em;
  flex-wrap: wrap;
}

.welcome-section.two-pane>div {
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 100%;
  padding: 2em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  flex: 2 0 40.9%;
}

.white-section {
  background-color: var(--contrast-body-color);
}

.blue-section {
  background-color: var(--blue-section);
  color: var(--blue-section-font);
}

.welcome-section:hover .welcome-logo>img {
  margin: 1.5em;
  width: 21em;
  height: 21em;
  transition: all .3s ease-in-out;
}

.welcome-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 2em;
}

.welcome-content.pane>div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  cursor: pointer;
  user-select: none;
}

.welcome-logo>img {
  margin: 2em;
  width: 20em;
  height: 20em;
  transition: all .2s ease-out;
}

.big-title {
  text-align: left;
}

.big-title>h1 {
  font-size: 3em;
}

.big-title.button-r > .button-main {
  width: 25%;
  margin-left: 72%;
}

/* Buttons */

.button-main {
  background-color: var(--button-color-main);
  text-align: center;
  padding: .4em;
  border-radius: 20px;
  font-weight: bolder;
  color: var(--button-font-color);
  cursor: pointer;
  user-select: none;
  transition: all .1s ease-in-out;
}

.button-main:hover {
  transition: all .1s ease-in-out;
}

.button-link {
  color: var(--contrast-font-color);
  font-weight: bold;
  text-decoration: underline;
  user-select: none;
  cursor: pointer;
}

.welcome-title {
  font-size: 3em;
  color: var(--main-font-color);
}

.welcome-subtitle {
  transform: translateY(-4em);
  color: var(--contrast-font-color);
}

.subtitle {
  color: var(--contrast-font-color);
}

.simple-description {
  text-align: left;
  padding: 1em 2em;
}

.pane-right-img {
  position:inherit;
  transform: translateX(25em) translateY(2em) rotate(-25deg) scale(2);
  width: 5em;
  height: 5em;
  transition: all .4s ease-out;
  user-select: none;
}

.welcome-content.pane:hover .pane-right-img {
  transition: all .5s ease-in-out;
  transform: translateX(24em) translateY(2em) rotate(-10deg) scale(2.5);
}

.welcome-content.pane:hover .button-link {
  transition: all .1s ease-in-out;
  background-color: var(--button-color-main);
}

.welcome-section.pricing-pane {
  background-color: var(--main-body-color);
  box-shadow: none;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
}

.welcome-content.pricing-pane {
  background-color: var(--contrast-body-color);
  border-radius: 20px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  width: 100%;
  padding: 2em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  flex: 1 0 25%;
  cursor: pointer;
  user-select: none;
}

.arrow-button {
  margin-top: 1em;
  padding: .2em;
  font-size: 2em;
  border-radius: 20px;
  transition: all .1s ease-in-out;
  color: var(--main-font-color);
}

.welcome-content.pricing-pane:hover .arrow-button {
  transition: all .2s ease-in-out;
  background-color: var(--button-color-main);
  color: var(--button-font-color);
}

.welcome-section.big-pricing:hover .arrow-button {
  transition: all .2s ease-in-out;
  background-color: var(--button-color-main);
  color: var(--button-font-color);
}

.welcome-section.big-pricing {
  cursor: pointer;
  user-select: none;
  padding: 2em;
}

.welcome-content.try-free {
  background-color: var(--blue-section);
  color: var(--button-font-color);
  cursor: pointer;
  user-select: none;
  border-radius: 20px;
  transition: all .1s ease-in-out;
  padding-block: 1em;
}

.welcome-content.try-free:hover {
  transition: all .2s ease-in-out;
  background-color: var(--button-color-main);
}

.old-price {
  text-decoration: rgba(255, 0, 0, 0.509) line-through;
  color: var(--faded-font-color);
}

.price {
  font-size: 1.5em;
}

/* TERMS AND CONDITIONS */
.terms-text {
  text-align: left;
  padding-inline: 5em;
  padding-block: 2em;
}

.terms-wrapper {
  color: var(--main-font-color);
}

/* INFO */
.info-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-around;
}

.info-pane {
  background-color: var(--contrast-body-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 2em;
  border-radius: 20px;
  margin-inline: 2em;
  margin-block: 5em;
}

/* THEME */
.themeButton {
  font-size: 25px;
  cursor: pointer;
  user-select: none;
  color: var(--main-font-color);
}

/* ACCOUNT */
.log-out {
  font-size: 25px;
  cursor: pointer;
  user-select: none;
  color: #ea5455;
}

/* forms */
/* profile */
.form-section-label {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.form-section-label>p { /* label */
  margin-bottom: 0;
}

.tripple-form-input {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  border-block: .5px solid var(--faded-font-color);
  padding-block: .8em;
  overflow-x: auto;
}

.tripple-form-input.req {
  background-color: var(--required-background);
  font-weight: bold;
}

.tripple-form-input>* {
  margin-right: 1em;
  width: 20%;
}

.new-layout-header {
  display: flex;
  flex-direction: row;
  padding-bottom: 2em;
  gap: 2em;
  flex-wrap: wrap;
}

.upload-file {
  padding: 0 !important;
}

.upload-file::-webkit-file-upload-button{
  background-color: var(--button-color-second);
  border: none;
  color: var(--button-font-color-second);
  font-family: 'Montserrat';
  cursor: pointer;
  border-radius: .357rem;
  padding: 0.438rem 1rem;
  content: 'Selectează fișier';
}

/* Invoices */
.invoice-scroll {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1em;
  height: auto;
  max-height: 25vh;
  width: auto;
}

.invoice-scroll.wide {
  width: 100%;
  gap: 0;
}

.invoice-tab {
  position: relative;
  width: 100%;
}

.invoice-tab.selector:hover {
  cursor: pointer;
  background-color: #00000032;
}

.invoice-tab.simple {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.invoice-tab.simple>* {
  width: calc(100%/5);
  min-width: 120px;
  margin: 0;
  padding: 1em;
  border-bottom: 2px solid var(--main-body-color);
}

.invoice-tab.simple>.actions-inside {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: .7em;
  height: 1.2em;
}

.invoice-tab.simple>.actions-inside>*{
  margin: 0;
}

.invoice-tab.simple>.actions-inside>.button-secondary{
  padding: .3rem;
  padding-inline: .7rem;
}

.invoice-tab.simple.stick-top {
  width: 100%;
  z-index: 1;
  position: sticky;
  top: 0;
}

.invoice-tab.simple.stick-top>* {
  background-color: var(--main-body-color);
  margin: 0;
  padding-block: 1em;
}

.invoice-table {
  width: 100%;
  background-color: var(--main-body-color);
  padding: 1em;
  border-radius: 0.357rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.state-bubble {
  /* background-color: orange; */
  border-radius: 0.357rem;
  font-weight: bolder;
  color: white;
  cursor: pointer;
}

.table-id {
  word-break: break-all;
  min-width: 60px;
  user-select: all;
}

.left-top-icon {
  position: absolute;
  left: 10px;
  top: 10px;
  /* color: orange; */
}

.no-margin {
  margin: 0 !important;
}

.no-padd {
  padding: 0 !important;
}

.bold {
  font-weight: bolder !important;
}

.history-row {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
}

/* Login and Register */
.login-wrapper {
  color: var(--main-font-color);
  display: flex;
  flex-direction: column;
  margin-bottom: 35em;
  justify-content: center;
  width: 100%;
}

.login-wrapper.popup {
  margin-bottom: 2em;
  overflow: auto;
}

.login-wrapper.no-bottom {
  margin-bottom: 10em;
}

.login-section {
  background-color: var(--contrast-body-color);
  margin-inline: 30%;
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.login-form {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-inline: 5em;
  margin-bottom: 3em;
}

.login-form.popup {
  margin-bottom: 0;
  padding-bottom: 0;
}

.login-form.popup>p {
  font-weight: bold;
}

.login-form>div {
  margin-top: 1em;
}

.login-form>div.dropdown-wrapper {
  margin-top: 0em;
}

.login-form>p {
  margin-bottom: 0;
}

.login-form>small {
  color: var(--faded-font-color);
}

.input-submit {
  margin-top: 1em;
  border-radius: 0.357rem;
  border: none;
  padding: 0.8rem 1rem;
  background-color: var(--button-color-main);
  font-family: 'Montserrat';
  color: var(--button-font-color);
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.input-submit:disabled {
  background-color: var(--button-color-main-disabled);
  cursor: not-allowed;
}

.input-submit:disabled:hover {
  box-shadow: none;
}

.input-submit:hover {
  box-shadow: rgba(149, 157, 165, 0.45) 0px 8px 24px;
  transition: all .2s ease-in-out;
}

.input-text {
  border: 1px solid var(--input-main-outline);
  background-color: var(--input-main-background);
  padding: 0.438rem 1rem;
  font-size: 1rem;
  border-radius: 0.357rem;
  color: var(--main-font-color);
  font-family: 'Montserrat';
}

.input-text:focus {
  outline: none !important;
  border: 1px solid var(--button-color-main);
}

.input-text:invalid {
  border-color: #ea5455!important;
}

.checkbox {
  accent-color: var(--button-color-main);
  transform: scale(1.5);
  margin-right: 1em;
}

.dropdown-wrapper {
  /* display: none; */
  transition: all .2s ease-in-out;
  transition-delay: .1s;
  height: 0;
  border-radius: 0.357rem;
  color: var(--main-font-color);
  border: 1px solid var(--input-main-outline);
  border-radius: 0 0 20px 20px;
  padding-inline: 1.5em;
  overflow: auto;
  max-height: 10rem;

  /* scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.dropdown-wrapper::-webkit-scrollbar {
  display: none; /* Chrome */
}

.dropdown-wrapper>p {
  transition: all .1s ease-in-out;
  cursor: pointer;
  margin: 0;
  padding-block: 1em;
}

.dropdown-wrapper>p:hover {
  transition: all .1s ease-in-out;
  background-color: var(--input-main-background);
  font-weight: bold;
}

.dropdown-search:focus + .dropdown-wrapper {
  /* display: block; */
  transition: all .2s ease-in-out;
  height: 10rem;
}

/* ERRORS */

.errors {
  text-align: left;
  margin-inline: 5em;
  color: #ea5455;
}

/* LOADING */
.spinner {
  color: var(--main-font-color);
  margin: .7em;
  font-size: 1.5em;

  animation-name: spin;
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

.generating-spin {
  color: var(--main-font-color);
  margin-top: .5em;
  margin-bottom: 0;
  font-size: 5em !important;

  animation-name: spin-big;
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

@keyframes spin-big {
  0% {
      transform: rotate(0deg) scale(1);
  }
  50% {
      transform: rotate(180deg) scale(1.3);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

/* MAIN APP */

.app-wrapper {
  color: var(--main-font-color);
  /* padding-bottom: calc(100vh - 1228px + 500px); */
  padding-bottom: 5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
}

.app-section {
  background-color: var(--contrast-body-color);
  width: 80%;
  max-width: 1100px;
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 3em;
}

.button-secondary {
  background-color: var(--button-color-second);
  font-size: 1rem;
  color: var(--button-font-color-second);
  text-align: center;
  padding: .4em;
  border-radius: 0.358rem;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  transition: all .1s ease-in-out;
  margin: .5em;
  padding: 0.486rem 1rem;
}

.button-secondary.adm-btn {
  background-color: var(--blue-section);
}

.button-secondary.disabled {
  color: var(--faded-font-color);
  cursor: not-allowed;
}

.button-secondary.disabled:hover {
  transform: translateY(0);
}

.button-secondary:hover {
  transition: all .1s ease-in-out;
  transform: translateY(-2px);
}

.btn-green {
  background-color: var(--button-color-main);
  font-family: 'Montserrat';
  border: none;
}

.edit-buttons>* {
  margin-inline: 1em;
  cursor: pointer;
  transition: all .1s ease-in-out;
}

.edit-buttons>*:hover {
  transition: all .1s ease-in-out;
  transform: translateY(-2px);
}

.app-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  transition: all .2s ease-in-out;
}

.w60 {
  width: 60%;
  transition: all .2s linear;
}

.w60.top-row {
  width: auto;
  transition: all .2s linear;
}

.w60.long-btn {
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
}

.selected-btn {
  background-color: var(--button-color-main);
  transition: all .2s linear;
}

.app-default-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x:auto;
}

.app-default-header {
  display: flex;
  flex-direction: row-reverse;
  padding: 1em;
  padding-inline: 2em;
  /* background-color: var(--button-color-second); */
  border-radius: .358rem .358rem 0 0;
}

.sub-view-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.small-info {
  color: var(--faded-font-color);
  font-size: small;
  margin-bottom: 1em;
}

.small-section {
  padding: 1em;
  background-color: var(--button-color-second);
  margin: 2em;
  border-radius: .358rem;
  color: var(--button-font-color-second);
  transition: all .1s ease-in-out;
  cursor: pointer;
}

.small-section:hover {
  background-color: var(--blue-section);
  transition: all .1s ease-in-out;
  transform: translateY(-3px);
}

.small-section.sub-card{
  overflow: hidden;
}

.small-section.sub-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255,255,255,0.4);
	-webkit-transition: none;
	   -moz-transition: none;
	        transition: none;
}

.small-section.sub-card:hover::after {
  width: 120%;
  background-color: rgba(255,255,255,0);
  
	-webkit-transition: all 0.4s ease-in-out;
	   -moz-transition: all 0.4s ease-in-out;
	        transition: all 0.4s ease-in-out;
}

.simple-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table;
  background-color: var(--main-body-color);
  border-radius: 0.358rem;
  overflow: auto;
}

.simple-table>thead>tr>th {
  background-color: var(--button-color-second);
  color: var(--button-font-color-second);
}

.simple-table>thead th{
  position: sticky; 
  top: 0; 
  z-index: 1; 
}

.simple-table>thead>tr>th:first-child {
  border-radius: 0.358rem 0 0 0;
}

.simple-table>thead>tr>th:last-child {
  border-radius: 0 0.358rem 0 0;
}

.simple-table>thead>tr>th, .simple-table>tbody>tr>td {
  padding: 1em;
  text-align: center;
  transition: all .1s linear;
}

.simple-table>tbody>tr>td:hover {
  background-color: var(--contrast-body-color);
}

.table-fixed-header {
  overflow: auto; 
  max-height: 20em;
}

/* Upload and work excel */

.import-wrapper {
  color: var(--main-font-color);
  padding-bottom: 10em;
  width: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.import-section {
  background-color: var(--contrast-body-color);
  width: 80%;
  max-width: 1100px;
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 0;
}

.handle-section {
  background-color: var(--contrast-body-color);
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 90%;
}

.handle-top-info {
  margin: 2em;
  margin-top: 0;
  text-align: left;
  transition: all .5s ease-in-out;
}

.handle-top-info>#collapse-btn { /* Collapse button */
  transition: all .3s ease-in-out;
  text-align: center;
  border-radius: .358rem;
  cursor: pointer;
  user-select: none;
}

.handle-top-info>#collapse-btn:hover { /* Collapse button */
  transition: all .3s ease-in-out;
  background-color: var(--main-body-color);
}

.handle-top-info>#collapse-btn>#inside {
  transition: all .3s ease-in-out;
}

.handle-top-info.collapsed>#collapse-btn>#inside {
  transition: all .3s ease-in-out;
  transform: rotate(180deg);
  margin-bottom: 0;
}

.handle-top-info.collapsed .handle-small-wrapper {
  transition: all .5s ease-in-out;
  height: 0;
}

.handle-small-wrapper {
  transition: all .5s ease-in-out;
  overflow: hidden;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: .5em;
}



.handle-multi-row, .handle-single-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: left;
  margin-inline: 2em;
}

.handle-multi-row>* {
  flex: 0 0 auto;
  width: 33.33333%;
}

.handle-single-row {
  margin-top: 2em;
  align-items: center;
}

.handle-single-row>* {
  flex: 0 0 auto;
  width: 10em;
  font-size: large;
}

/* Generate button */
.handle-single-row>*:first-child {
  width: 12em;
}

.handle-single-row>*.type-blue {
  background-color: var(--blue-section);
}

.checkbox-wrapper>* {
  cursor: pointer !important;
  user-select: none;
}

.upload-here {
  background-color: var(--main-body-color);
  border-radius: .358rem;
  padding-block: 3em;
  cursor: pointer;
}

.upload-icon {
  font-size: 4em;
  transition: all .3s ease-in-out;
  animation : lightHover 1.2s ease-in-out;
}

.upload-here:hover .upload-icon {
  transform: rotate(90deg) scale(1.5);
  transition: all .3s ease-in-out;
  color: var(--button-color-main);
}

@keyframes lightHover{
  0%{transform : scale(1);}
  50%{transform: scale(2); color: var(--button-color-main)}
  100%{transform: scale(1);}
}

.width-wrapper {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  flex-wrap: wrap;
}

.progress-bar-wrapper {
  background-color: var(--main-body-color);
  border-radius: .358rem;
  width: 80%;
  max-width: 80%;
}

.progress-bar {
  padding-block: .2em;
  font-weight: bold;
  text-align: center;
  max-width: 100%;
  border-radius: .358rem;
  background-color: var(--button-color-main);
  color: var(--button-font-color);
  transition: all .5s ease-in-out;
}

.small-form-wrapper {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.small-form-section {
  width: 50%;
}

.steps-buttons {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin-top: 2em;
}

/* Excel result table */
.res-table-wrapper {
  color: var(--table-font-color);
  border: .2em solid var(--blue-section);
  border-radius: .358rem;
}

.res-table-section {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  overflow: auto;
  max-width: max-content;
  max-height: 50em;
}

.res-table-row {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
}

.res-table-row.rows>* {
  transition: all .1s linear;
}

.res-table-row>* {
  color: var(--table-font-color);
  border: 1px solid var(--table-cell-color-border);
  outline: none !important;
  font-family: 'Montserrat';
  padding: 0.438rem 1rem;
  font-size: 1rem;
  text-align: left;
  cursor: default;
  text-overflow: ellipsis;
  height: 1.4em;
}

.res-table-row>select {
  height: 2.4em;
}

.res-table-row>*:focus {
  cursor: text;
  outline: none !important;
  border: 1.5px solid var(--button-color-main);
}

.res-table-row:nth-child(odd)>* {
  background-color: var(--table-row-color-odd);
}

.res-table-row:nth-child(even)>* {
  background-color: var(--table-row-color-even);
}

.res-table-row.header-cell {
  position: sticky !important;
  top: 0;
}

.res-table-row.footer-cell {
  position: sticky !important;
  bottom: 0;
}

.res-table-row.header-cell>*, .res-table-row.footer-cell>* {
  text-align: left;
  text-shadow: .5px 0px .5px var(--table-header-font-color);
  color: var(--table-header-font-color);
  background-color: var(--blue-section);
  border-top: 1px transparent;
  text-overflow: clip;
}

.res-table-row>*:first-child {
  border-left: 1px transparent;
}

.res-table-row>*:last-child {
  border-right: 1px transparent;
}

.res-table-input.req {
  background-color: var(--button-color-second) !important;
}

.res-table-input.error-cell.nr-crt {
  border-radius: .357rem;
  cursor: pointer;
}

.res-table-input.error-cell.nr-crt:hover {
  transform: translateX(.2em);
}

.page-btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.page-btn-wrapper>* {
  border: 1px solid var(--blue-section);
  outline: none !important;
  font-family: 'Montserrat';
  padding: 0.438rem 0rem;
  font-size: 1rem;
  text-align: center;
  background-color: transparent;
  color: var(--blue-section);
  font-weight: bold;
}

.page-btn-wrapper>div {
  border: 1px solid var(--blue-section);
  padding: 0.438rem 2rem;
  cursor: pointer;
  color: var(--blue-section);
}

.page-btn-wrapper>div:first-child {
  border-radius: .357rem 0 0 .357rem;
}

.page-btn-wrapper>div:last-child {
  border-radius: 0 .357rem .357rem 0;
}

.res-table-input.select-type {
  background-image:
    linear-gradient(45deg, transparent 50%, var(--table-font-color) 50%),
    linear-gradient(135deg, var(--table-font-color) 50%, transparent 50%);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - .5em) .5em;
  background-size:
    5px 5px,
    5px 5px,
    1.5em 1.5em;
  background-repeat: no-repeat;
  cursor: pointer;
}

.error-cell {
  background-color: var(--error-background) !important;
  color: var(--table-header-font-color) !important;
  text-shadow: .5px 0px .5px var(--table-header-font-color);
}

.modal-wrapper {
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  padding-top: clamp(130px, 20vh, 12%); /* Location of the box */
  padding-bottom: 1vh;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: var(--contrast-body-color);
  margin: auto;
  overflow: auto;
  padding-top: .5em;
  padding-inline: .5em;
  padding-bottom: 20px;
  width: 70%;
  border-radius: .357rem;
  max-height: 65%;
  transition: all .3s ease-in-out;
  color: var(--main-font-color);
}

.modal-content.confirm-box {
  width: 40vw;
}

.modal-header {
  background-color: var(--main-body-color);
  border-radius: .357rem;
  margin-bottom: 1em;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
}

.modal-header.confirm-box>* {
  margin-block: 0;
  padding: .5em;
  padding-inline: 1em;
}

.modal-header>* {
  padding: 1em;
}

.modal-top {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 2em;
}

.modal-top>*:last-child {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-inline: 1em;
  gap: 1em;
}

.modal-top>*:last-child>* {
  width: 15em;
}

.modal-body-wrapper {
  overflow: auto;
}

.modal-body-wrapper.confirm-box {
  margin-block: 2em;
}

.modal-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  margin-inline: 2em;
  max-height: 30em; /* max width of the table of the modal */
  min-width: 30em; /* max width of the table of the modal */
}

.modal-body>div { /* List item */
  width: auto !important;
  height: auto !important;
}

.modal-bottom {
  display: flex;
  flex-direction: row;
  justify-content: right;
  flex-wrap: wrap;
}

.modal-bottom.center {
  justify-content: center;
}

.modal-form {
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
  gap: .5em;
  margin-bottom: 2em;
}

.modal-form>h3 {
  margin-bottom: 0;
}

.modal-form-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal-form-section>p {
  margin-bottom: 0;
}

.modal-form-section>input {
  width: 30vw;
}

.popup-table {
  overflow: auto;
  margin: 1em;
  /* display: flex;*/
  justify-content: center; 
}

.popup-table>* {
  max-height: 25em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.modal-element {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-top: 1px solid var(--table-cell-color-border);
  cursor: pointer;
  padding-bottom: 1em;
  pointer-events: auto !important;
}

.modal-element:hover {
  background-color: var(--input-main-background);
  border-radius: .357rem;
}

.modal-element>* {
  margin-top: 1em;
  width: 22em;
  overflow: auto;
  text-align: left;
  margin: 5em;
}

.modal-element>*:first-child {
  text-align: center;
}

.modal-element>*:last-child {
  max-height: 5em;
}

.buttons-2-wrapper {
  width: 65%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.buttons-2-wrapper>* {
  flex-grow: 1;
}

.blue-btn {
  background-color: var(--blue-section);
}

/* ADMIN panel */
.admin-info-section {
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.admin-info-element {
  display: flex;
  flex-direction: row;
  gap: .5em;
  flex-shrink: 0;
  align-items: flex-start;
  width: 80%;
  flex-wrap: wrap;
}

.admin-info-element>*:first-child {
  font-weight: bold;
}

.admin-info-element>div:last-child {
  cursor: pointer;
  user-select: all;
}

.admin-info-element>h3 {
  text-decoration: underline;
  margin-bottom: 0;
  margin-top: 2em;
}

.admin-action-section {
  display: flex;
  width: 80%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.admin-action-section.col {
  flex-direction: column;
  align-items: baseline;
}

.admin-action-section.col>p {
  margin-bottom: 0;
}

.admin-action-section.end {
  justify-content: flex-end;
}

.filters-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;
  align-items: center;
  justify-content: center;
}

.filter {
  flex: 0 0 23.333333%;
}

.filter>* {
  margin-block: 0;
}

.popup-error-row {
  padding-block: 1em;
  border-bottom: 1px solid var(--main-body-color);
}

.popup-error-wrapper {
  overflow: auto;
  max-height: 15em;
}

.no-bot-text {
  font-family: 'Shadows Into Light', cursive;
  font-size: xx-large;
  background-color: white;
  padding: .3em;
  margin-top: 1em;
  color: black !important;
  font-weight: bold !important;
  text-align: center;
  user-select: none;
  border-radius: 3px;
  text-decoration: underline line-through;
  font-style: italic;
  border: none;
}

.no-bot-text:hover {
  background-color: black;
}

.admin-table-scroll {
  position: relative;
  width:100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  max-height: 650px;
}
.admin-table-scroll table {
  width: 100%;
  min-width: 1280px;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}
.admin-table-scroll th,
.admin-table-scroll td {
  padding: 5px 10px;
  border: 1px solid #000;
  background: #fff;
  vertical-align: top;
  color: #000;
  overflow-wrap: break-word;
  word-break: break-all;
}
.admin-table-scroll thead th {
  background: #333;
  color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.admin-table-stick > th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background: #333;
  color: #fff;
}
.admin-table-scroll thead th:first-child {
  z-index: 2;
  width: 7rem;
}

/* CHARTS */

.gauges-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-block: 2em;
  flex-wrap: wrap;
  gap: 5em;
}

/* semi gauge chart */
.semi-donut{
  --percentage-semi-donut: 0;
  --fill: #FF3D00;
  width: 300px;
  height: 120px;
  position: relative;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  overflow: hidden;
  color: var(--fill);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing : border-box;
}

.semi-donut::after{
  content: '';
  width: 300px;
  height: 300px;
  border: 25px solid;
  border-color : rgba(0,0,0,0.15) rgba(0,0,0,0.15) var(--fill) var(--fill);
  position: absolute;
  border-radius: 50%;
  left: 0;
  top: 0;
  box-sizing : border-box;
  transform: rotate( calc( 1deg * ( -45 + var(--percentage-semi-donut) * 1.8 ) ) );
  animation : fillAnimation 1.2s ease-in-out;
}

.semi-donut>div {
  color: var(--main-font-color);
}

@keyframes fillAnimation{
  0%{transform : rotate(-45deg);}
  50%{transform: rotate(135deg);}
}

/* MEDIA SCREENS */

@media only screen and (max-width: 900px) {
  .bubble {
    width: 49%;
  }
}

@media only screen and (max-width: 700px) {

  .app-row>.button-secondary.top-row {
    flex: 1;
  }

  .modal-content.confirm-box {
    width: 70vw;
  }

  .after-header {
    padding-bottom: 7em;
  }
  
  .welcome-logo {
    padding-bottom: 0;
  }

  .welcome-logo>img {
    margin: 2em;
    width: 6em;
    height: 6em;
  }

  .welcome-section:hover .welcome-logo>img {
    margin: 2em;
    width: 6em;
    height: 6em;
  }

  .big-title {
    padding: 2em;
    padding-top: 0;
    text-align: center;
  }

  .big-title>h1 {
    font-size: 2em;
  }

  .big-title.button-r > .button-main {
    width: 50%;
    margin-left: 22%;
  }

  .welcome-title {
    font-size: 2em;
  }

  .login-form {
    margin-inline: 1em;
    margin-bottom: 3em;
  }

  .login-section>h1 {
    font-size: 1.5em;
  }
  
  .login-wrapper {
    margin-bottom: 10em;
  }

  .welcome-content.pricing-pane {
    flex: 1 0 40%;
  }
}

@media only screen and (max-width: 1000px) {
  
  .modal-content.confirm-box {
    width: 60vw;
  }

  .handle-multi-row>* {
    flex: 1 0 auto;
    width: 100%;
    text-align: center;
    margin-bottom: 2em;
  }

  .tripple-form-input {
    justify-content: center;
  }

  .tripple-form-input>* {
    width: auto;
  }

  .header-wrapper {
    z-index: -1;
  }

  .header {
    flex-direction: column;
    transform: translateY(-50vh);
    transition: all .1s ease-in-out;
    align-items: center;
  }

  .header.open {
    transform: translateY(0);
  }

  .right-side {
    flex-direction: column;
    padding-top: 3em;
  }

  .menu-button {
    z-index: 3;
    display: block;
  }

  .footer-wrapper {
    height: 450px;
  }

  .footer {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .list-block {
    border-bottom: 2.5px solid var(--main-font-color);
    padding-bottom: 2em;
    border-right: 0;
  }

  .list-block:last-child {
    border-bottom: 5px transparent
  }

  .login-section {
    margin-inline: 10%;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
  border-left: 5px solid transparent;
  transition: all .1s ease-in-out;
  color: var(--main-font-color);
}

a:hover {
  border-left: 5px solid var(--contrast-font-color);
  transition: all .1s ease-in-out;
}

/* 404 error */

.not-found-container {
  margin-top: 5vh;
  margin-bottom: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found-container>div {
  width: 60%;
}

.not-found-container>div>h1, .not-found-container>div>h2 {
  color: var(--main-font-color);
}

.not-found-code {
  font-size: 5em;
}

.not-found-code>span {
  user-select: none;
  margin-inline: .1em;
  display: inline-block;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

.break-word {
  word-break: break-all;
}

.bear {
  width: 160px;
  height: 185px;
  position: relative;
  background-color: #fff;
  border-radius: 100px 100px 0 0;
  position: absolute;
  left: calc(50% - 80px);
}
.bear:after {
  content: "";
  position: absolute;
  width: 100px;
  height: 125px;
  left: 50%;
  top: 25px;
  transform: translateX(-50%);
  background-image: radial-gradient(circle, #000 48%, transparent 55%),
    radial-gradient(circle, #000 48%, transparent 55%),
    radial-gradient(circle, #fff 30%, transparent 45%),
    radial-gradient(circle, #000 48%, transparent 51%),
    linear-gradient(#000 20px, transparent 0),
    linear-gradient(#cfecf9 60px, transparent 0),
    radial-gradient(circle, #cfecf9 50%, transparent 51%),
    radial-gradient(circle, #cfecf9 50%, transparent 51%);
  background-repeat: no-repeat;
  background-size: 16px 16px, 16px 16px, 10px 10px, 42px 42px, 12px 3px,
    50px 25px, 70px 70px, 70px 70px;
  background-position: 25px 10px, 55px 10px, 36px 44px, 50% 30px, 50% 85px,
    50% 50px, 50% 22px, 50% 45px;
  animation: faceLift 3s linear infinite alternate;
}
.bear:before {
  content: "";
  position: absolute;
  width: 140%;
  height: 125px;
  left: -20%;
  top: 0;
  background-image: radial-gradient(circle, #fff 48%, transparent 50%),
    radial-gradient(circle, #fff 48%, transparent 50%);
  background-repeat: no-repeat;
  background-size: 65px 65px;
  background-position: 0px 12px, 145px 12px;
  animation: earLift 3s linear infinite alternate;
}

@keyframes faceLift {
  0% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(-30%);
  }
}
@keyframes earLift {
  0% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0px);
  }
}

.bear-space {
  padding-block: 60px;
}

/* For service scroll top info */
#scroll-container {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 5;
  overflow: hidden;
  background-color: orange;
}

#scroll-text {
  font-size: large;
  /* animation properties */
  /* -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%); */

  /* -moz-animation: infinite-scroll-animation 30s linear infinite;
  -webkit-animation: infinite-scroll-animation 30s linear infinite; */
  /* animation: infinite-scroll-animation 60s linear infinite; */
}

/* for Firefox */
@-moz-keyframes infinite-scroll-animation {
  from { -moz-transform: translateX(100%); }
  to { -moz-transform: translateX(-100%); }
}

/* for Chrome */
@-webkit-keyframes infinite-scroll-animation {
  from { -webkit-transform: translateX(100%); }
  to { -webkit-transform: translateX(-100%); }
}

@keyframes infinite-scroll-animation {
  from {
    -moz-transform: translateX(10%);
    -webkit-transform: translateX(10%);
    transform: translateX(10%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

/* History page */
.simple-row-data-view {
  display: flex;
  flex-direction: column;
  background-color: var(--main-body-color);
  border-radius: .35rem;
  padding-block: 15px;
}

.simple-data-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-block: 2px solid var(--contrast-body-color);
  min-height: 100px;
}

.simple-data-row:first-child {
  border-top: 0;
  padding-top: 0;
}

.simple-data-row:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.simple-data-row-left, .simple-data-row-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}

.simple-data-row-left {
  word-break: break-all;
}

.simple-data-row-left div:not(:first-child) { /* Description */
  opacity: .8;
  font-size: small;
}

.simple-data-row-right {
  text-align: right;
  justify-content: flex-end;
  align-items: normal;
}

.stats-view {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px;
  background: var(--main-body-color);
  border-radius: .35rem;
}

/* for BETA TESTING TODO remove for prod */
.soon-bg{
	  overflow:hidden;
		background:#b4ddf0;
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
    user-select: none;
}

.soon-bg>h1 {
  position: fixed;
  text-align: center;
  top: 30%;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 5em;
}
.mountain, .mountain-two, .mountain-three {
    position: absolute;
    bottom: 0;
    border-left: 150px solid transparent;
    border-right: 150px solid transparent;
    border-bottom: 180px solid #7ac1e4;
    z-index: 1;
}
.mountain-two { 
    left: 80px;
    bottom: -20px;
    opacity: .3;
    z-index: 0;
}
.mountain-three {
    left: -60px;
    bottom:-10px;
    opacity: .5;
    z-index: 0;
}
.mountain-top {
    position: absolute;
    right: -65px;
    border-left: 65px solid transparent;
    border-right: 65px solid transparent;
    border-bottom: 77px solid #ceeaf6;
    z-index: 2;
}
.mountain-cap-1, .mountain-cap-2, .mountain-cap-3 {
    position: absolute;
    top: 70px;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 25px solid #ceeaf6;
}
.mountain-cap-1 { left: -55px; }
.mountain-cap-2 { left: -25px; }
.mountain-cap-3 { left: 5px; }
.cloud, .cloud:before, .cloud:after {
  position: absolute;
  width: 70px;
	height: 30px;
	background: #fff;
	-webkit-border-radius: 100px / 50px;
	border-radius: 100px / 50px;
}
.cloud { 
  bottom: 100px;
  -webkit-animation: cloud 50s infinite linear;
          animation: cloud 50s infinite linear;
}
@-webkit-keyframes cloud {
    0%   { left: -100px; }
    100% { left: 1000px; } 
}
@keyframes cloud {
   
    0%   { left: -100px; }
    100% { left: 1000px; } 
}
.cloud:before {
  content: '';
  left: 50px;
}
.cloud:after {
  content: '';
  left: 25px;
  top: -10px;
}