.helper-wrapper {
  color: var(--main-font-color);
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-bottom: 100px;
}

.main-view {
    width: 90%;
    height: 70vh;
    max-width: 1400px;
    background: var(--contrast-body-color);
    border-radius: .3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    gap: 10px;
}

.top-left-btn {
    position: absolute;
    left: 10px;
    top: 10px;
}

.wide-search {
    width: 87%;
}

.result-box {
    background: var(--main-body-color);
    height: 80%;
    width: 90%;
    margin-bottom: 20px;
    border-radius: .5rem;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.result-box-header {
    display: flex;
    width: 90%;
    height: 40px;
    font-size: 13px;
}

.result-item {
    display: flex;
    justify-content: space-between;
    height: 60px;
    width: 99%;
    margin-top: 5px;
    border-radius: .5rem;
    background-color: var(--contrast-body-color);
    cursor: pointer;
}

.result-item > div, .result-box-header > div {
    overflow: auto;
    flex: 1;
    box-sizing: border-box;
    padding: 10px;
}

.result-item > div:first-child, .result-box-header > div:first-child {
    flex: 2;
}

.new-car-form {
    height: auto;
    width: 100%;
}

.form-btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.form-btns > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    border-radius: .5rem;
    background-color: var(--contrast-body-color);
    transition: all .3s;
}

.form-btns > div:hover {
    scale: 1.05;
    cursor: pointer;
}