/*///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Cairo&family=Hind+Madurai:wght@400;500&family=Montserrat:wght@400;500;600;700&family=Shadows+Into+Light&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Cairo&family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo&family=Hind+Madurai:wght@500display=swap'); */

:root {
  --main-font-color: #333333;
  --faded-font-color: #333333ac;
  --contrast-font-color: #7367F0;
  --button-color-main: #44D886;
  --button-color-main-disabled: #44d8874a;
  --button-color-second: #7367F0;
  --button-font-color: #ffffff;
  --button-font-color-second: #ffffff;
  --main-body-color: #F8F8F8;
  --contrast-body-color: #ffffff;
  --blue-section: #007EFC;
  --blue-section-font: #ffffff;
  --input-main-outline: #d8d6de;
  --input-main-background: #fbfbfb;
  --required-background: #007efc41;

  --table-row-color-odd: #dfdfdf;
  --table-row-color-even: #f8f8f8;
  --table-cell-color-border:rgb(207, 207, 207);
  --table-font-color: #333333;
  --table-header-font-color: #ffffff;
  --error-background: #EA5455;

  /* Chart colors */
  --chart-good: #44D886;
  --chart-warn: #FF5722;
  --chart-bad: #CC3333;
}

[display-theme="dark"] {
  --main-font-color: #D0D2D6;
  --faded-font-color: #D0D2D6ac;
  --contrast-font-color: #7367F0;
  --button-color-main: #44D886;
  --button-color-second: #7367F0;
  --button-font-color: #ffffff;
  --main-body-color: #171D31;
  --contrast-body-color: #283046;
  --blue-section: #007EFC;
  --blue-section-font: #ffffff;
  --input-main-outline: #404656;
  --input-main-background: #333c56;

  --table-row-color-odd: #222b47;
  --table-row-color-even: rgb(63, 75, 108);
  --table-cell-color-border:#171D31;
  --table-font-color: #D0D2D6;
}

body {
  scroll-behavior: smooth;
  margin: 0;
  font-family: 'Montserrat', 'Hind Madurai', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-body-color);

  position: relative;
  min-height: 100vh;
}

body::after {
  content: '';
  display: block;
  height: 250px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 1000px) {
  body::after {
    height: 450px;
  }
}

/* h1 {font-family:'Montserrat', sans-serif;}

h2, h3, h4, h5, h6 {font-family:'Cairo', sans-serif;}

div, p, small, li {font-family:'Hind Madurai', sans-serif;} */