#invoice-template {
  font-size: 13px;
  background-color: white;
  /* border: 1px solid black; */
  padding-inline: 15mm;
  padding-block: 10mm;
  max-width: 180mm;
  height: clamp(270mm, 270mm, 270mm);
  position: relative;
}

.invoice-title {
  width: 100%;
  text-align: center;
  margin-bottom: 2em;
}

.invoice-title>.info-block {
  margin-top: .5em;
}

.main-title {
  font-size: xx-large;
  font-weight: bold;
  text-transform: uppercase;
}

.top-left-info {
  display: flex;
  flex-direction: row;
  text-align: left;
  gap: .5em;
  position: absolute;
  left: 15mm;
  top: 10mm;
}

.top-right-info {
  display: flex;
  flex-direction: column;
  text-align: right;
  position: absolute;
  right: 15mm;
  top: 10mm;
}

.top-right-info>*, .top-left-info>* {
  margin: 0;
}

.series-big {
  font-weight: bold;
  font-size: medium;
}

.invoice-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.info-block {
  display: flex;
  flex-direction: column;
}

.info-block>* {
  margin: 0;
}

.small-title {
  border-bottom: 1px solid black;
  width: clamp(100px, 100%, 200px);
}

.clamp-title {
  width: clamp(150px, 100%, 180);
}

.bold-title {
  font-size: large;
  font-weight: bold;
  text-transform: uppercase;
  overflow-wrap: break-word;
}

.invoice-body {
  margin-top: 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.inv-table {
  width: 100%;
  border-block: 1px solid black;
  border-collapse: collapse; 
}

.inv-tbody>tr>td, .inv-tbody>tr>th {
  text-align: center;
  overflow-wrap: break-word;
}

.inv-tbody>tr>th {
  padding-block: 1em;
  border-bottom: 1px solid black;
}

.inv-tbody>tr>td {
  padding-block: 1em;
}

.inv-tbody>tr>td:nth-child(2) {
  overflow-wrap: break-word;
  max-width: 200px;
}

.inv-tbody>tr:last-child {
  border-top: 2px solid black;
}

.flex-width {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.total-payment>* {
  margin: 0;
}

.total-payment {
  width: 35%;
  display: flex;
  justify-content: space-between;
}

.invoice-footer {
  width: calc(100% - 30mm);
  position: absolute;
  bottom: 10mm;
  left: 15mm;
}

.footer-legal>* {
  margin: 0;
  font-size: smaller;
}

.footer-legal {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  text-align: center;
  width: 100%;
}

.logo-invoice {
    width: 200px;
}